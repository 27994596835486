<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in itemsMenuPrincipal">
      <default-list-group v-if="item.items" :key="`group-${i}`" :item="item" />

      <default-list-item v-else :key="`item-${i}`" :item="item" />

      <v-divider
        v-if="item.divider"
        :key="`item-devider-${i}`"
        inset
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'DefaultList',

  components: {
    DefaultListGroup: () => import('./ListGroup'),
    DefaultListItem: () => import('./ListItem'),
  },

  props: {
    itemsMenuPrincipal: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
